class Video {
    constructor(element) {

        this.element = element
        this.video = this.element.querySelector('video')
        this.bindings()
        // this.setButtonState()
    }


    bindings() {


        this.playBtn = this.element.querySelector('.btn-play-pause')
        this.muteBtn = this.element.querySelector('.btn-mute')
        this.playIcon = this.playBtn?.querySelector('.play')
        this.pauseIcon = this.playBtn?.querySelector('.pause')
        this.muteIcon = this.muteBtn?.querySelector('.mute')
        this.unmuteIcon = this.muteBtn?.querySelector('.unmute')

        this.playBtn?.addEventListener('click', this.togglePlay.bind(this))
        this.muteBtn?.addEventListener('click', this.toggleMute.bind(this))

        if (this.video.classList.contains('lazy')) {
            this.observer = new IntersectionObserver(this.observe.bind(this), { rootMargin: "150px 0px 150px 0px" })
            this.observer.observe(this.video)
        }

    }

    observe(entries, observer) {

        entries.forEach(function (video) {
            if (video.isIntersecting) {
                for (let source in video.target.children) {
                    let videoSource = video.target.children[source];
                    if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE" && videoSource.classList.contains("lazy")) {
                        videoSource.src = videoSource.dataset.src;
                    }
                }

                video.target.load();
                video.target.classList.remove("lazy");
                observer.unobserve(video.target);
            }
        });
    };

    setButtonState() {

        if (this.pauseIcon) {

            if (this.video.paused) {
                this.pauseIcon.classList.add('hidden')
                this.playIcon.classList.remove('hidden')
            } else {
                this.pauseIcon.classList.remove('hidden')
                this.playIcon.classList.add('hidden')
            }
        }


        if (this.unmuteIcon) {
            if (this.video.muted) {
                this.muteIcon.classList.add('hidden')
                this.unmuteIcon.classList.remove('hidden')
            } else {
                this.muteIcon.classList.remove('hidden')
                this.unmuteIcon.classList.add('hidden')
            }
        }
    }

    togglePlay() {

        if (this.video.paused) {
            this.video.play()

        } else {
            this.video.pause()
        }
        this.setButtonState()

    }

    toggleMute() {
        if (this.video.muted) {
            this.video.muted = false
        } else {
            this.video.muted = true
        }
        this.setButtonState()
    }

}

// IIFE to invoke component on mount
(function init() {


    const videoEls = document.querySelectorAll('[data-video]')

    videoEls.forEach((el, i, arr) => {

        if (el.getAttribute('bound') !== null) return

        const video = new Video(el)

        el.setAttribute('bound', "")

        const id = arr.length > 1 ? `Video_${i}` : "Video"

        self.Decimal.components[id] = { ...video, async: true }

    })

})()
